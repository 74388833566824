@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.preloaderModule {
  position: fixed;
  display: flex;
  width: 100%;
  height: 150vh;
  background-color: #000;
  z-index: 1000;
  opacity: 1;
  perspective: 1000;
  backface-visibility: hidden;
}

.preloadModuleFirstVisitOnHome {
  @extend .preloaderModule;

  background-color: transparent;
}

.preloaderContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.preloaderProgressBarContainer {
  position: absolute;
  top: 25vh;
  left: 0;
  width: 100%;
  background-color: #000;
  height: 6px;
}

.preloaderProgressBar {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  background-color: #FFF;
  height: 6px;
  transform: scaleX(0) translateZ(0);
  transform-origin: left;

  transition: transform 0.3s ease;
}

.preloaderGraphic {
    position: absolute;
    display: inline-block;
    width: 60px;
    height: 60px;

    top: 50%;
    left: 50%;

    margin-left: -30px;
    margin-top: -80px;

    // background-image: url(../../assets/Smile-White.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    animation: spinner 3s linear infinite;
    transform-origin: center;

    @include media-breakpoint-up(md) {
      width: 80px;
      height: 50px;
      margin-left: -40px;
      margin-top: -25px;
    }

    @include media-breakpoint-up(lg) {
      width: 95px;
      height: 65px;

      margin-left: -42px;
      margin-top: -32px;
    }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}
