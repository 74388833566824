.App {
  position: relative;
  background-color: #FFF;
  /* padding-bottom: 100px; */
  backface-visibility: hidden;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.navIntersector {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(155,0,0,0.5);
}
