@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.marqueeModule {
  display: flex;
  width: 100%;
}

.marqueeModuleInvert {
  filter: invert(1);
}

.marqueeContainer {
  position: relative;
  width: 100%;
  margin-top: 95px;
  margin-bottom: 55px;

  @include media-breakpoint-up(md) {
    margin-top: 145px;
    margin-bottom: 70px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 190px;
    margin-bottom: 90px;
  }

  p {
    @include headline-medium();
  }
}


.marqueeWrapper {
  position: relative;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  white-space: nowrap;
  min-height: 45px;

  @include media-breakpoint-up(md) {
    min-height: 60px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 90px;
  }
}

.marqueeInner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 24s linear infinite;
}

.marqueeInner p {
  @include headline-medium();
  display: flex;

  a {
    color: #000;
  }

  span {
    display: inline-block;
    width: 50px;
    height: 35px;

    background-image: url(../../assets/Smile.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    animation: spinner 3s linear infinite;
    transform-origin: center;

    @include media-breakpoint-up(md) {
      width: 80px;
      height: 50px;
      margin-top: -2px;
    }

    @include media-breakpoint-up(lg) {
      width: 95px;
      height: 65px;
    }
  }
}

@keyframes marquee {
  0% {
      transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
      transform: translate3d(var(--move-final), 0, 0);
  }
}

@keyframes spinner {
  to {transform: rotate(-360deg);}
}
