@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.workSamplesWrapper {
  position: relative;
  background-color: #000;

  padding-bottom: 100px;

  @include media-breakpoint-up(md) {
    padding-bottom: 150px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 200px;
  }
}

.bgSwitcher {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}


.testDiv {
  position: fixed;
  top: 40px;
  left: 40px;
  background-color: #00FF00;
  width: 100px;
  height: 100px;
  color: #FFF;
  z-index: 100;
  line-height: 1;
}
