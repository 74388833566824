@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.endOfPageModule {
  position: relative;
  display: block;
  width: 100%;
  height: 100px;
}

.endOfPageModuleMobile {
  @extend .endOfPageModule;

  height: 30px;
  background-color: transparent;

}

.mobileFooterContainer {
  @include bootstrap-container();
  @include paragraph-medium();
}

.mobileFooterRow {
  @include bootstrap-row();
  line-height: 1;
}

.mobileFooterCopyright {
  @include make-col-ready();
  @include make-col(1, 6);
}

.mobileFooterDevCredit {
  @include make-col-ready();
  @include make-col(5, 6);

  a {
    color: #000;
  }
}
