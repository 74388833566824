@import 'src/utils/scss/variables';
@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.5;
  pointer-events: none;
}

.container {
  @include bootstrap-container();
  height: 100%;
}

.row {
  @include bootstrap-row();
  height: 100%;
}

.col {
  @include bootstrap-col();
  height: 100%;
}

.colContent {
  display: block;
  background-color: #FF0000;
  height: 100%;
}
