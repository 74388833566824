@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.introModule {
  display: flex;
  position: relative;
}

.introContainer {
  @include bootstrap-container();
  margin-top: 95px;

  @include media-breakpoint-up(md) {
    margin-top: 145px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 190px;
  }
}

.introRow {
  @include bootstrap-row();
}

.introHomeHeadline {
  @include headline-large();
  color: #FFF;
  padding-bottom: 95px;

  @include make-col-ready();
  @include make-col(5, 6);

  @include media-breakpoint-up(md) {
    @include make-col(10, 10);
    padding-bottom: 145px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(11, 12);
  }
}

.introProjectCategories {
  @include make-col-ready();
  @include make-col(6, 6);

  @include media-breakpoint-up(md) {
    @include make-col(4, 10);
    padding-bottom: 150px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
    padding-bottom: 200px;
  }

  h2 {
    @include headline-medium();
  }
}

.introProjectDetails {
  @include make-col-ready();
  @include make-col(6, 6);
  margin-top: 0;
  padding-bottom: 100px;

  @include media-breakpoint-up(md) {
    @include make-col(6, 10);
    margin-top: 0;
    padding-bottom: 150px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
    padding-bottom: 200px;
  }
}

.introProjectDetailsHorizontalLine {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  overflow: visible;
}


.introProjectDetailsHeader {
  @include bootstrap-row();
  margin-top: 5px;

  @include media-breakpoint-up(md) {
    margin-top: 5px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 8px;
  }
}

.introProjectDetailsResponsibility {
  @include paragraph-medium();

  @include make-col-ready();
  @include make-col(3, 6);

  @include media-breakpoint-up(md) {
    @include make-col(3, 6);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(2, 6);
  }
}

.introProjectDetailsClient {
  @include paragraph-medium();

  @include make-col-ready();
  @include make-col(2, 6);

  @include media-breakpoint-up(md) {
    @include make-col(2, 6);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3, 6);
  }
}

.introProjectDetailsYear {
  @include paragraph-medium();

  @include make-col-ready();
  @include make-col(1, 6);
  text-align: right;

  @include media-breakpoint-up(md) {
    @include make-col(1, 6);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(1, 6);
  }
}

.introProjectDescription {
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;

  @include media-breakpoint-up(md) {
    padding-top: 30px;
    padding-bottom: 35px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 45px;
    padding-bottom: 90px;
  }

  p {
    @include paragraph-large();
  }
}

.introProjectDetailsFooter {
  @include bootstrap-row();
  margin-bottom: 5px;
  align-items: flex-end;

  @include media-breakpoint-up(md) {
    margin-bottom: 5px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 8px;
  }
}

.introProjectLink {
  @include make-col-ready();
  @include make-col(3, 6);

  @include media-breakpoint-up(md) {
    @include make-col(3, 6);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(2, 6);
  }

  a {
    @include paragraph-medium();
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;

    span {
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 10px;
      background-color: #000;
      margin-right: 10px;

      @include media-breakpoint-up(md) {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.introCategoryListFooter {
  @include make-col-ready();
  @include make-col(3, 6);

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.introCategoryList {
  margin: 0;
  padding: 0;
  margin-top: 0;

  @include media-breakpoint-up(md) {
    display: block;
    margin-top: 20px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 30px;
  }

  li {
    @include paragraph-medium();
    list-style-type: none;
  }
}
