@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';


.navModule {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  // mix-blend-mode: exclusion !important;
  pointer-events: none;
  top: 0;
  opacity: 1;
}

.nav {
  position: relative;
  width: 100%;
  height: 100%;
}

.menu {
  --gutter-x: #{map-get($grid-gutter-widths, sm)};
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - (var(--gutter-x) * 2));
  height: calc(100% - (var(--gutter-x) * 2));

  margin: var(--gutter-x);
  padding: 0;

  li {
    @include paragraph-medium();
    position: absolute;
    color: #FFF;
    list-style: none;
    pointer-events: all;
    // transition: color 0.3s ease-out;

    &.logo {
      top: 0;
      left: 0;
      cursor: pointer;
    }

    &.about {
      bottom: 0;
      left: 0;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        display: none;
      }

      div {
        display: inline-block;
        line-height: 1;
        margin-right: 75px;

        &:not(:first-child) {
          margin-right: 75px;
          visibility: hidden;
          display: inline-block;
          margin-right: 20px;
          opacity: 0;
          transition: opacity 0s ease, visibility 0s ease 0s;
        }

        &.show {
          visibility: visible;
          transition: opacity 0s ease, visibility 0s ease 0s;
          opacity: 1;
        }

        a {
          color: inherit;
        }
      }

      @include media-breakpoint-down(lg) {
        div {
          &:first-child {
            margin-right: 40px;
          }
        }
      }
    }

    &.aboutMobile {
      @extend .about;

      @include media-breakpoint-down(md) {
        display: inline-block;
        font-size: 16px;
        bottom: 95px;
      }
    }

    &.pathfinder,
    &.closeButton {
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 10px;
        background-color: #FFF;
        margin-right: 10px;
        /* transition: background-color 0.3s ease-out; */

        @include media-breakpoint-up(md) {
          width: 10px;
          height: 10px;
        }
      }
    }

    &.contact {
      bottom: 0;
      right: 0;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &.contactMobile {
      @extend .contact;

      @include media-breakpoint-down(md) {
        display: block;
        font-size: 16px;
        left: 0;
        bottom: 8px;
      }
    }
  }

  .contactButton {
    display: inline-block;
    cursor: pointer;
  }

  .contactInfo {
    position: relative;
    display: inline-block;
    overflow: visible;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 30px;
      background-color: transparent;
      top: -15px;
      right: -7px;
      z-index: 0;
    }
  }

  .contactInfoItem {
    position: relative;
    display: inline-block;
    margin-left: 15px;
    z-index: 1;
    text-decoration: underline;
    color: #FFF;

    a {
      color: #FFF;
    }

    padding-top: 3px;
    padding-bottom: 3px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 1;
      display: block;
      margin-left: 0;
    }

    @include media-breakpoint-up(md) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  @include media-breakpoint-up(md) {
    --gutter-x: #{map-get($grid-gutter-widths, md)};
    width: calc(100% - (var(--gutter-x) * 2));
    height: calc(100% - (var(--gutter-x) * 2));
    margin: var(--gutter-x);
  }
}

.colContent {
  display: block;
  background-color: #FF0000;
}

.workOverviewOverlay {
  position: fixed;
  width: 100%;
  height: 150%;
  top: -25%;
  left: 0;
  background-color: #000;
  z-index: 5;
}

.overlayContentContainer {
  @include bootstrap-container();
  height: 100%;
}

.overlayContentRow {
  @include bootstrap-row();
  height: 100%;
  position: relative;
}

.closeWrapper {
  @include make-col-ready();
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.closeButtonOld {
  --gutter-x: #{map-get($grid-gutter-widths, sm)};
  @include paragraph-medium();
  display: flex;
  align-items: center;
  cursor: pointer;

  background-color: transparent;
  border: none;
  color: #FFF;
  margin-top: var(--gutter-x);
  padding: 0;

  @include media-breakpoint-up(md) {
    --gutter-x: #{map-get($grid-gutter-widths, md)};
    margin-top: var(--gutter-x);
  }

  span {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 10px;
    background-color: #FFF;
    margin-right: 10px;

    @include media-breakpoint-up(md) {
      width: 10px;
      height: 10px;
    }
  }
}

.projectHero {
  @include make-col-ready();
  @include make-col(3, 6);
  @include make-col-offset(3, 6);
  position: absolute;
  height: 100%;
  transition: opacity 0.4s;
  opacity: 1;

  &:empty {
    opacity: 0;
  }

  @include media-breakpoint-up(md) {
    @include make-col(5, 10);
    @include make-col-offset(5, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
    @include make-col-offset(3, 12);

    width: 42%;
    margin-left: 29%;
  }
}

.projectHeroPortrait {
  @extend .projectHero;
  @include make-col(2, 6);
  @include make-col-offset(4, 6);

  width: calc(33.3333333333% + 10px);
  margin-left: calc(66.6666666667% - 10px);

  @include media-breakpoint-up(md) {
    @include make-col(4, 10);
    @include make-col-offset(6, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);
    @include make-col-offset(4, 12);
  }
}

.projectHeroWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;
  opacity: 1;

  @include media-breakpoint-down(lg) {
    margin-left: 20px;
  }

  @include media-breakpoint-down(md) {
    margin-left: 10px;
  }
}

.projectHeroWrapperHidden {
  @extend .projectHeroWrapper;
  opacity: 0;
}

.projectListCol {
  @include make-col-ready();
}

.projectListWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.projectList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 80%;
  color: #FFF;

  &:hover {
    color: #444;
  }
}

.projectListTouch {
@extend .projectList;

  &:hover {
    color: #FFF;
  }
}

.project {
  @include headline-large();
  position: relative;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #FFF;
  }
}

.projectTouch {
  @extend .project;

  &:hover {
    color: #444;
  }
}

.projectActive {
  color: #FFF;
  &:hover {
    color: #FFF;
  }
}

.projectMuted {
  color: #444;
}

.projectNumber {
  @include headline-large();
  color: #FFF;
  position: relative;
  width: 30%;
  text-align: right;
  transition: opacity 0.4s;
}

.projectNumberHidden {
  @extend .projectNumber;
  opacity: 0;
}
