@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.paginatorModule {
  display: flex;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  @include media-breakpoint-up(md) {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  span {
    @include paragraph-medium();
  }

  p {
    @include headline-medium();

  }
}

.paginatorContainer {
  @include bootstrap-container();
}

.paginatorRow {
  @include bootstrap-row();
  align-items: center;
}

.workSampleMedium  {
  @include make-col-ready();
  @include make-col(4, 6);
  @include make-col-offset(1, 6);

  @include media-breakpoint-up(md) {
    @include make-col(8, 10);
    @include make-col-offset(1, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(8, 12);
    @include make-col-offset(2, 12);
  }
}

.paginatorPrev  {
  @include make-col-ready();
  @include make-col(3, 6);

  @include media-breakpoint-up(md) {
    @include make-col(5, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
  }
}

.paginatorNext  {
  @include make-col-ready();
  @include make-col(3, 6);
  text-align: right;

  @include media-breakpoint-up(md) {
    @include make-col(5, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
  }
}

.paginatorHitArea  {
  display: inline-block;
  cursor: pointer;

  &:hover {
    p {
      &.paginatorTitleHover {
          text-decoration: underline;
      }
    }
  }
}
