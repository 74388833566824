@import 'src/utils/scss/bootstrap';

*, ::after, ::before {
  box-sizing: border-box;
}

html.mobile {
  height: 100%;
  overflow: hidden;

  body {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

html, body {
  background-color: #000;
}

html.lockscroll {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img, video {
	max-width: 100%;
	width: auto;
}

div {
  line-height: 0;
}

p {
  margin: 0;
}

* {
	outline: none;
	&:focus {
		outline:none;
	}

  user-select: none;
}

.container {
  @include make-container(map-get($container-paddings, sm));
  max-width: map-get($container-max-widths, sm);

  @include media-breakpoint-up(md) {
    @include make-container(map-get($container-paddings, md));
    max-width: map-get($container-max-widths, md);
  }

  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
  }

  &.fullheight {
    height: 100%;
  }
}

.row {
  @include make-row(map-get($grid-gutter-widths, sm));

  @include media-breakpoint-up(md) {
    @include make-row(map-get($grid-gutter-widths, md));
  }

  @include media-breakpoint-up(lg) {
    @include make-row(map-get($grid-gutter-widths, lg));
  }

  &.fullheight {
    height: 100%;
  }
}

.col {
  @include make-col-ready();
  @include make-col(1, 6);

  @include media-breakpoint-up(md) {
    @include make-col(1, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(1, 12);
  }
}
