@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.awardsModule {
  display: flex;
  position: relative;
}

.awardsContainer {
  @include bootstrap-container();
  margin-top: 95px;

  @include media-breakpoint-up(md) {
    margin-top: 145px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 190px;
  }
}

.awardsRow {
  @include bootstrap-row();
}

.awardsHeadline {
  @include make-col-ready();
  @include make-col(6, 6);

  @include media-breakpoint-up(md) {
    @include make-col(3, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);
  }

  h2 {
    @include paragraph-medium();
  }
}

.awardsList {
  @include make-col-ready();
  @include make-col(6, 6);
  margin-top: 45px;

  @include media-breakpoint-up(md) {
    @include make-col(7, 10);
    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(8, 12);
  }
}

.award {
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    margin-bottom: 35px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 50px;
  }
}

.awardHorizontalLine {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  overflow: visible;
}


.awardDetails {
  @include bootstrap-row();
  margin-top: 5px;

  @include media-breakpoint-up(md) {
    margin-top: 5px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 8px;
  }
}

.awardBullet {
  @include paragraph-medium();

  @include make-col-ready();
  @include make-col(1, 6);

  @include media-breakpoint-up(md) {
    @include make-col(1, 7);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(1, 8);
  }

  span {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 10px;
    background-color: #000;
    margin-left: 0;
    margin-top: 6px;

    @include media-breakpoint-up(md) {
      width: 10px;
      height: 10px;
      margin-top: 5px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 13px;
    }
  }
}

.awardName {
  @include paragraph-large();

  @include make-col-ready();
  @include make-col(3, 6);

  @include media-breakpoint-up(md) {
    @include make-col(4, 7);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(5, 8);
    margin-top: 3px;
  }
}

.awardCategory {
  @include paragraph-medium();

  @include make-col-ready();
  @include make-col(2, 6);
  text-align: right;

  @include media-breakpoint-up(md) {
    @include make-col(2, 7);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(2, 8);
    margin-top: 8px;
  }
}
