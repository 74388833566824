@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.logosModule {
  display: flex;
  position: relative;
}

.logosContainer {
  @include bootstrap-container();
  margin-top: 95px;

  @include media-breakpoint-up(md) {
    margin-top: 145px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 190px;
  }
}

.logosRow {
  @include bootstrap-row();
}

.logosHeadline {
  @include make-col-ready();
  @include make-col(6, 6);

  @include media-breakpoint-up(md) {
    @include make-col(3, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);
  }

  h2 {
    @include paragraph-medium();
  }
}

.logosList {
  @include make-col-ready();
  @include make-col(6, 6);
  margin-top: 45px;

  @include media-breakpoint-up(md) {
    @include make-col(7, 10);
    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(8, 12);
  }
}

.logosListItem {
  margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    margin-bottom: 100px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}


.logosListItemRow {
  @include bootstrap-row();
  svg {
    height: 28px;
    width: auto;
  }

  @include media-breakpoint-up(md) {
    svg {
      height: 30px;
      width: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    svg {
      height: 40px;
      width: auto;
    }
  }
}

.logosListItemCol1 {
  @include make-col-ready();
  @include make-col(3, 6);
  margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    @include make-col(2, 7);
    margin-bottom: 0px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(2, 8);
  }
}

.logosListItemCol2 {
  @include make-col-ready();
  @include make-col(3, 6);
  margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    @include make-col(2, 7);
    margin-bottom: 0px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3, 8);

    svg {
      margin-left: 17%;
    }
  }
}

.logosListItemCol3 {
  @include make-col-ready();
  @include make-col(3, 6);

  @include media-breakpoint-up(md) {
    @include make-col(2, 7);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(2, 8);
  }
}

.logosListItemCol4 {
  @include make-col-ready();
  @include make-col(3, 6);

  @include media-breakpoint-up(md) {
    @include make-col(1, 7);
    text-align: right;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(1, 8);
    text-align: right;
  }
}
