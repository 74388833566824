@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.coverModule {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center top;
  align-items: center;
}

.heroContainer {
  @include bootstrap-container();
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.heroRow {
  @include bootstrap-row();
  height: 100%;
  position: relative;
}

.heroImagePortrait {
  height: 100%;
  @include make-col-ready();
  @include make-col(6, 6);

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @include media-breakpoint-up(md) {
    @include make-col(6, 10);
    @include make-col-offset(2, 10);
  }

  @include media-breakpoint-up(md) {
    @include make-col(6, 10);
    @include make-col-offset(2, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
    @include make-col-offset(3, 12);

    img {
      max-width: 82%;
    }
  }
}

.heroImage {
  height: 100%;
  @include make-col-ready();
  @include make-col(6, 6);

  @include media-breakpoint-up(md) {
    @include make-col(8, 10);
    @include make-col-offset(1, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
    @include make-col-offset(3, 12);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.homeTYContainer {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.homeTYWrapper {
  position: absolute;
  @include make-col-ready();
  @include make-col(6, 6);

  @include media-breakpoint-up(md) {
    @include make-col(10, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(8, 12);
    @include make-col-offset(2, 12);
  }
}

.homeTLetter {
  @include letters-home();
  color: #FFF;
  display: inline-block;
  position: absolute;
}

.homeYLetter {
  @include letters-home();
  color: #FFF;
  display: inline-block;
  position: absolute;
}

.homeImage {
  @extend .heroImage;
  position: absolute;
}

.homeImagePortrait {
  @extend .heroImagePortrait;
  position: absolute;
}

.heroHomeImage {
  position: relative;
  width: 80%;
}

.heroHomeT {
  position: absolute;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  background-image: url(../../assets/T-Mobile.png);
  top: -10px;
  left: 15px;
  width: 44%;
  height: 180px;

  @include media-breakpoint-up(md) {
    height: 180px;
    top: -20px;
    left: -20px;
    background-image: url(../../assets/T-Desktop.png);
  }

  @include media-breakpoint-up(lg) {
    height: 290px;
    top: -25px;
    left: -25px;
    background-image: url(../../assets/T-Desktop.png);
  }
}

.heroHomeY {
  position: absolute;
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-image: url(../../assets/Y-Mobile.png);
  bottom: -10px;
  right: 15px;
  width: 52%;
  height: 180px;

  @include media-breakpoint-up(md) {
    height: 180px;
    bottom: -20px;
    right: -25px;
    background-image: url(../../assets/Y-Desktop.png);
  }

  @include media-breakpoint-up(lg) {
    height: 290px;
    bottom: -25px;
    right: -30px;
    background-image: url(../../assets/Y-Desktop.png);
  }
}

.heroImageWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.heroTitle {
  height: 100%;
  @include make-col-ready();
  @include make-col(6, 6);

  @include media-breakpoint-up(md) {
    @include make-col(10, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(12, 12);
  }
}

.heroTitleWrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;

  @include media-breakpoint-up(md) {
    padding-bottom: 72px;
  }

  @include media-breakpoint-up(lg) {
    align-items: center;
    padding-bottom: 0;
  }

  &.alignBottom {
    align-items: flex-end;
    padding-bottom: 90px;
  }
}

.heroTitleText {
  @include headline-large();
  color: #FFF;
  position: relative;
  width: 100%;
}

.heroTitleBreadcrumb {
  @include headline-large();
  color: #FFF;
  position: relative;
  width: 30%;
  text-align: right;
}

.heroTextBlack {
  color: #000;
}
