@import 'src/utils/scss/bootstrap';

@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin make-full-bleed {
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;

  @include media-breakpoint-up(lg) {
    max-height: 880px;
  }
}

@mixin neue-haas-bold {
  font-family: neue-haas-grotesk-display,sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin neue-haas-regular {
  font-family: neue-haas-grotesk-display,sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin letters-home {
  font-family: neue-haas-grotesk-display,sans-serif;
  font-style: normal;
  font-size: 80px;
  line-height: 40px;
  letter-spacing: 0.01em;

  @include media-breakpoint-up(md) {
    font-size: 128px;
    line-height: 96px;
  }

  @include media-breakpoint-up(lg) {
    font-weight: 500;
    font-size: 192px;
    line-height: 96px;
  }
}

@mixin headline-large {
  @include neue-haas-bold();
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.01em;
  margin: 0;

  @include media-breakpoint-up(md) {
    font-size: 64px;
    line-height: 64px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 96px;
    line-height: 96px;
  }
}

@mixin headline-medium {
  @include neue-haas-bold();
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.01em;
  margin: 0;

  @include media-breakpoint-up(md) {
    font-size: 42px;
    line-height: 42px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 58px;
    line-height: 58px;
  }
}

@mixin paragraph-large {
  @include neue-haas-regular();
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
  margin: 0;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin paragraph-medium {
  @include neue-haas-regular();
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  margin: 0;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 20px;
  }
}

@mixin bootstrap-container() {
  @include make-container(map-get($container-paddings, sm));
  max-width: map-get($container-max-widths, sm);

  @include media-breakpoint-up(md) {
    @include make-container(map-get($container-paddings, md));
    max-width: map-get($container-max-widths, md);
  }

  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
  }
}

@mixin bootstrap-row() {
  @include make-row(map-get($grid-gutter-widths, sm));

  @include media-breakpoint-up(md) {
    @include make-row(map-get($grid-gutter-widths, md));
  }

  @include media-breakpoint-up(lg) {
    @include make-row(map-get($grid-gutter-widths, lg));
  }
}

@mixin bootstrap-col() {
  @include make-col-ready();
  @include make-col(1, 6);

  @include media-breakpoint-up(md) {
    @include make-col(1, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(1, 12);
  }
}
