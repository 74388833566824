@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.workGridModule {
  display: flex;
  position: relative;
}

.workGridContainer {
  @include bootstrap-container();
  padding-top: 95px;

  @include media-breakpoint-up(md) {
    padding-top: 145px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 190px;
  }
}

.workGridRow {
  @include bootstrap-row();
  align-items: center;
  margin-top: 100px;

  &:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    margin-top: 140px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 140px;
  }
}

.workGridItem {
  position: relative;
  cursor: pointer;
  @include make-col-ready();
  @include make-col(5, 6);

  &:hover {
    z-index: 5;
  }

  &:last-child:not(:only-child) {
    @include make-col-offset(1, 6);
    margin-top: 100px;
  }

  @include media-breakpoint-up(md) {
    @include make-col(5, 10);

    &:last-child:not(:only-child) {
      @include make-col-offset(1, 10);
      margin-top: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    @include make-col(5, 12);

    @include make-col-offset(1, 12);
    &:last-child:not(:only-child) {
      @include make-col-offset(1, 12)
    }
  }
}

.workGridItemPortrait {
  position: relative;
  cursor: pointer;
  @include make-col-ready();
  @include make-col(4, 6);


  &:last-child:not(:only-child) {
    @include make-col-offset(2, 6);
    margin-top: 100px;
  }

  @include media-breakpoint-up(md) {
    @include make-col(4, 10);

    &:last-child:not(:only-child) {
      @include make-col-offset(1, 10);
      margin-top: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);

    @include make-col-offset(1, 12);
    &:last-child:not(:only-child) {
      @include make-col-offset(1, 12);
    }
  }
}

.projectThumbnail {
  line-height: 0;
  margin-bottom: 8px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 16px;
  }
}

.projectThumbnailMuted {
  @extend .projectThumbnail;

  opacity: 0.3;
}

.workGridItemFooter {
  @include bootstrap-row();

  > div {
    @include paragraph-medium();
  }
}

.workGridItemFooterInvert {
  filter: invert(1);
}

.workGridItemFooterHide {
  visibility: hidden;
}

.workGridItemDot {
  @include make-col-ready();
  @include make-col(1, 4);

  span {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 10px;
    background-color: #000;
    margin-right: 10px;
    margin-top: 2px;

    @include media-breakpoint-up(md) {
      width: 10px;
      height: 10px;
    }
  }
}

.workGridItemDotExpanded {
  @extend .workGridItemDot;
  @include make-col(1, 5);

}

.workGridItemTitleAndRole {
  @include make-col-ready();
  @include make-col(2, 4);
}

.workGridItemTitleAndRoleExpanded {
  @include make-col-ready();
  @include make-col(3, 5);
}

.workGridItemPage {
  @include make-col-ready();
  @include make-col(1, 4);
  text-align: right;
}

.workGridItemPageExpanded {
  @include make-col-ready();
  @include make-col(1, 5);
  text-align: right;
}

.workGridItemHoverOverlayModule {
  position: absolute;
  top: 50%;
  height: auto;
  width: 100vw;
  pointer-events: none;
  left: calc(-50vw + 60%);
  transform: translateY(-60%);
  z-index: 10;

  &[data-number="odd"] {
    left: calc(-50vw + 40%);

    @include media-breakpoint-up(md) {
      left: calc(-50vw);
    }
  }

  @include media-breakpoint-up(md) {
    left: calc(-50vw + 100%);
  }
}

.workGridItemHoverOverlayModulePortrait {
  position: absolute;
  top: 50%;
  height: auto;
  width: 100vw;
  pointer-events: none;
  left: calc(-50vw + 75%);
  transform: translateY(-70%);
  z-index: 10;

  &[data-number="odd"] {
    left: calc(-50vw + 25%);

    @include media-breakpoint-up(md) {
      left: calc(-50vw - 25%);
    }
  }

  @include media-breakpoint-up(md) {
    left: calc(-50vw + 125%);
  }
}

.workGridItemHoverOverlayContainer {
  @include bootstrap-container();
  height: 100%;
}

.workGridItemHoverOverlayRow {
  @include bootstrap-row();
  height: 100%;
}


.workGridItemHoverOverlayWrapper {
  display: flex;
  @include make-col-ready();
  @include make-col(6, 6);

  @include media-breakpoint-up(md) {
    @include make-col(10, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(12, 12);
  }
}

.workGridItemHoverOverlayText {
  @include headline-large();
  color: #FFF;
  position: relative;
  width: 70%;
}

.workGridItemHoverOverlayBreadcrumb {
  @include headline-large();
  color: #FFF;
  position: relative;
  width: 30%;
  text-align: right;
}
