@import 'src/utils/scss/mixins';
@import 'src/utils/scss/bootstrap';

.workSampleModule {
  display: flex;
  position: relative;
  margin-top: 100px;

  @include media-breakpoint-up(md) {
    margin-top: 150px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 200px;
  }

  &:first-child {
    margin-top: 0;
  }
}


.workSampleModulePadded {
  @extend .workSampleModule;
  padding-top: 50px;

  @include media-breakpoint-up(md) {
    padding-top: 75px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 100px;
  }
}

.workSampleModuleFullBleed {
  @extend .workSampleModule;
  background-color: transparent;

  height: 380px;

  @include media-breakpoint-up(md) {
    height: 550px;
  }

  @include media-breakpoint-up(lg) {
    height: 780px;
  }
}

.workSampleContainer {
  @include bootstrap-container();
}

.workSampleRow {
  @include bootstrap-row();
  align-items: center;
  margin-top: 100px;

  @include media-breakpoint-up(md) {
    margin-top: 150px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 200px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.workSampleFull {
  width: 100%;
  height: 100%;
  line-height: 0;

  img {
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.workSampleMedium  {
  @include make-col-ready();
  @include make-col(4, 6);
  @include make-col-offset(1, 6);

  @include media-breakpoint-up(md) {
    @include make-col(8, 10);
    @include make-col-offset(1, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(8, 12);
    @include make-col-offset(2, 12);
  }
}

.workSampleXLarge  {
  @include make-col-ready();
  @include make-col(6, 6);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  img {
    align-self: center;
  }

  @include media-breakpoint-up(md) {
    @include make-col(10, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(12, 12);
  }
}

.workSampleLarge  {
  @include make-col-ready();
  @include make-col(6, 6);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  img {
    align-self: center;
  }

  @include media-breakpoint-up(md) {
    @include make-col(10, 10);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(10, 12);
    @include make-col-offset(1, 12);
  }
}

.workSampleImageHalf {
  @include make-col-ready();
  @include make-col(6, 6);

  &:not(:first-child) {
    margin-top: 100px;
  }

  @include media-breakpoint-up(md) {
    @include make-col(5, 10);
    &:not(:first-child) {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6, 12);
  }
}

/*.workSampleImageVariety {
  @include make-col-ready();
  @include make-col(6, 6);

  &:not(:first-child) {
    margin-top: 100px;
  }

  @include media-breakpoint-up(md) {
    @include make-col(4, 10);

    &:not(:first-child) {
      margin-top: 0;
    }

    &:last-child {
      @include make-col-offset(1, 10);
      @include make-col(5, 10);
    }
  }

  @include media-breakpoint-up(lg) {
    @include make-col(5, 12);
    @include make-col-offset(1, 12);

    &:first-child {
      @include make-col(4, 12);
    }

    &:last-child {
      @include make-col-offset(1, 12);
      @include make-col(5, 12);
    }
  }
}
*/



.workSampleImageVariety {
  position: relative;
  cursor: pointer;
  @include make-col-ready();
  @include make-col(5, 6);

  &:hover {
    z-index: 5;
  }

  &:last-child:not(:only-child) {
    @include make-col-offset(1, 6);
    margin-top: 100px;
  }

  @include media-breakpoint-up(md) {
    @include make-col(5, 10);

    &:last-child:not(:only-child) {
      @include make-col-offset(1, 10);
      margin-top: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    @include make-col(5, 12);

    @include make-col-offset(1, 12);
    &:last-child:not(:only-child) {
      @include make-col-offset(1, 12)
    }
  }
}

.workSampleImageVarietyPortrait {
  position: relative;
  cursor: pointer;
  @include make-col-ready();
  @include make-col(4, 6);

  &:hover {
    z-index: 5;
  }

  &:last-child:not(:only-child) {
    @include make-col-offset(2, 6);
    margin-top: 100px;
  }

  @include media-breakpoint-up(md) {
    @include make-col(4, 10);

    &:last-child:not(:only-child) {
      @include make-col-offset(1, 10);
      margin-top: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);

    @include make-col-offset(1, 12);
    &:last-child:not(:only-child) {
      @include make-col-offset(1, 12);
    }
  }
}

